exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-karta-saita-js": () => import("./../../../src/pages/karta-saita.js" /* webpackChunkName: "component---src-pages-karta-saita-js" */),
  "component---src-pages-master-galina-js": () => import("./../../../src/pages/master/galina.js" /* webpackChunkName: "component---src-pages-master-galina-js" */),
  "component---src-pages-narashchivanie-volos-besplatno-js": () => import("./../../../src/pages/narashchivanie-volos/besplatno.js" /* webpackChunkName: "component---src-pages-narashchivanie-volos-besplatno-js" */),
  "component---src-pages-narashchivanie-volos-dlina-js": () => import("./../../../src/pages/narashchivanie-volos/dlina.js" /* webpackChunkName: "component---src-pages-narashchivanie-volos-dlina-js" */),
  "component---src-pages-narashchivanie-volos-gollivudskoe-js": () => import("./../../../src/pages/narashchivanie-volos/gollivudskoe.js" /* webpackChunkName: "component---src-pages-narashchivanie-volos-gollivudskoe-js" */),
  "component---src-pages-narashchivanie-volos-holodnoe-js": () => import("./../../../src/pages/narashchivanie-volos/holodnoe.js" /* webpackChunkName: "component---src-pages-narashchivanie-volos-holodnoe-js" */),
  "component---src-pages-narashchivanie-volos-index-js": () => import("./../../../src/pages/narashchivanie-volos/index.js" /* webpackChunkName: "component---src-pages-narashchivanie-volos-index-js" */),
  "component---src-pages-narashchivanie-volos-kapsulnoe-js": () => import("./../../../src/pages/narashchivanie-volos/kapsulnoe.js" /* webpackChunkName: "component---src-pages-narashchivanie-volos-kapsulnoe-js" */),
  "component---src-pages-narashchivanie-volos-lentochnoe-js": () => import("./../../../src/pages/narashchivanie-volos/lentochnoe.js" /* webpackChunkName: "component---src-pages-narashchivanie-volos-lentochnoe-js" */),
  "component---src-pages-narashchivanie-volos-stoimost-js": () => import("./../../../src/pages/narashchivanie-volos/stoimost.js" /* webpackChunkName: "component---src-pages-narashchivanie-volos-stoimost-js" */),
  "component---src-pages-nashi-raboty-js": () => import("./../../../src/pages/nashi-raboty.js" /* webpackChunkName: "component---src-pages-nashi-raboty-js" */),
  "component---src-pages-okrashivanie-volos-js": () => import("./../../../src/pages/okrashivanie-volos.js" /* webpackChunkName: "component---src-pages-okrashivanie-volos-js" */),
  "component---src-pages-perezvonite-mne-js": () => import("./../../../src/pages/perezvonite-mne.js" /* webpackChunkName: "component---src-pages-perezvonite-mne-js" */),
  "component---src-pages-prajs-js": () => import("./../../../src/pages/prajs.js" /* webpackChunkName: "component---src-pages-prajs-js" */),
  "component---src-pages-salon-js": () => import("./../../../src/pages/salon.js" /* webpackChunkName: "component---src-pages-salon-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

